export type AbilityAction =
  | 'create'
  | 'list'
  | 'read'
  | 'update'
  | 'delete'
  | 'search';

export enum AbilitySubject {
  Activity = 'Activity',
  ActivityComment = 'ActivityComment',
  Contact = 'Contact',
  Media = 'Media',
  Email = 'Email',
  Employee = 'Employee',
  EmployeeContract = 'EmployeeContract',
  Note = 'Note',
  Health = 'Health',
  Project = 'Project',
  ProjectCalculation = 'ProjectCalculation',
  User = 'User',
  PurchaseOrder = 'PurchaseOrder',
  PurchaseInvoice = 'PurchaseInvoice',
  PurchaseInvoiceLine = 'PurchaseInvoiceLine',
  Invoice = 'Invoice',
  Product = 'Product',
  RateGroup = 'RateGroup',
  SalesQuote = 'SalesQuote',
  Supplier = 'Supplier',
  SupplierProduct = 'SupplierProduct',
  TimeEntry = 'TimeEntry',
  QuickEstimate = 'QuickEstimate',
  QuotePreview = 'QuotePreview',
  Warehouse = 'Warehouse',
  WarehouseStock = 'WarehouseStock',
  WarehouseReceipt = 'WarehouseReceipt',
  WarehouseTransaction = 'WarehouseTransaction',
}

export type AbilitySubjectTuple =
  | [AbilityAction, AbilitySubject]
  | ['create:admin' | 'update:admin', AbilitySubject.User]
  | ['approve', AbilitySubject.TimeEntry]
  | ['regenerateThumbnails', AbilitySubject.Media];
